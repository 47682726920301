import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import styled from 'styled-components'

export const InviteContractorModal = ({ show, onHide, contractorEmail, onInviteLater, onInviteNow, isLoading }) => {
  return (
    <StyledModal show={show} centered onHide={onHide}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20">Invite contractor</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="color_grey">
          Would you like to invite {contractorEmail} onto the RemoFirst platform for them to start self-onboarding
          (including ID, proof of address and other necessary checks)?
        </Typography>
        <Typography className="color_grey mt-4">You can always invite them later.</Typography>
      </Modal.Body>
      <Modal.Footer>
        <OverlayTrigger placement="top" overlay={<Tooltip>Under development...</Tooltip>}>
          <span>
            <Button type="button" size="small" priority="secondary" onClick={onInviteLater} disabled>
              Invite later
            </Button>
          </span>
        </OverlayTrigger>
        {/* <Button type="button" size="small" priority="secondary" onClick={onInviteLater} disabled={isLoading}>
          Invite later
        </Button> */}
        <Button type="submit" size="small" priority="primary" onClick={onInviteNow} disabled={isLoading}>
          Invite now
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 500px;
  }
  .modal-header {
    padding: 24px 24px 15.5px 24px;
    margin-bottom: 0;
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    padding-top: 0;
  }
`
