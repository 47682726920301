import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { ADDITIONAL_PAYMENT_TYPE_KEY } from '@pages/additional-payments/utils'
import { getCostCalculatorList } from '@services/employer-cost.service'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'

export const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

const expenseTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`

const Form = ({ data, payrollData, isLoading, onClose, onSave, isDisabled }) => {
  const { currencies, userProfile } = useApp()
  const [apTypeKeys, setApTypeKeys] = useState()

  const {
    control,
    setValue,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ap_type_id: data?.ap_type || undefined,
      amount: data.amount || '',
      name: data.name || '',
      currency: data.currency.id || '',
      description: data.description || '',
      tax: data.tax,
    },
  })
  const addPmtListQuery = useQuery(
    [getCostCalculatorList.key, payrollData?.country, payrollData?.partner],
    () =>
      getCostCalculatorList.fetch({
        usage_area: 'additional_payment',
        limit: 100,
        offset: 0,
        country_id: payrollData.country?.id,
        partner: payrollData?.partner
          ? {
              name: payrollData.partner.name,
              user_id: payrollData.partner.id,
              id: payrollData.partner.partner_profile_id || 0,
            }
          : undefined,
        company: {
          user_id: userProfile.id,
        },
      }),
    {
      enabled: !!payrollData?.country && !!payrollData?.partner,
      onSuccess: (res) => {
        setApTypeKeys(
          res?.results.reduce((acc, value) => {
            return { ...acc, [value.id]: value }
          }, {})
        )
      },
    }
  )

  useEffect(() => {
    if (data?.sub_ap_type && apTypeKeys) {
      const ap_type_id = Object.values(apTypeKeys).find((value) => value.name === data?.sub_ap_type)?.id
      if (ap_type_id) setValue('ap_type_id', ap_type_id)
    }
  }, [data?.sub_ap_type, apTypeKeys])

  const submit = (formValues) => {
    const category = apTypeKeys && apTypeKeys[formValues.ap_type_id?.value || formValues.ap_type_id]
    let body = {
      amount: formValues.amount,
      name: formValues.name,
      description: formValues.description,
      currency: formValues.currency.value,
      ap_type: category
        ? ADDITIONAL_PAYMENT_TYPE_KEY[category.additional_payment_category]
        : formValues.ap_type_id?.value || formValues.ap_type_id,
      sub_ap_type: category ? category.name : undefined,
    }
    if (formValues.tax) {
      body = { ...body, tax: formValues.tax }
    }
    onSave(body)
  }

  const options = useMemo(() => {
    return addPmtListQuery.data?.results.length
      ? addPmtListQuery.data.results.map((e) => ({ value: e.id, label: e.name }))
      : paymentTypes
  }, [addPmtListQuery.data])

  return (
    <StyledForm noValidate className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="ap_type_id"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-984450"
                  {...field}
                  label="Payment type"
                  options={data?.source === 'EXPENSE_MANAGEMENT' ? expenseTypes : options}
                />
              )
            }}
          />
          {errors.ap_type_id && (
            <Typography className="text_regular__14 color_red">{errors.ap_type_id.message}</Typography>
          )}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="amount"
            rules={{
              required: 'Field is required',
              validate: {
                minlength: (v) =>
                  /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
              },
            }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Amount"
                placeholder="Enter the adjustment amount"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="currency"
            rules={{ required: 'Currency is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-3614AA"
                  {...field}
                  label="Currency"
                  options={currencies.map((country) => ({
                    value: country.id,
                    label: country.name || country.short_code,
                  }))}
                />
              )
            }}
          />
          {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Input
            data-testid="Form-92F75B"
            {...register('name', { required: 'Field is required' })}
            label="Reason name"
            placeholder="Title"
          />
          {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
        </div>
        <div className="col-12 mt-2">
          <TextArea placeholder="Description..." {...register('description')} />
          {errors.description && (
            <Typography className="text_regular__14 color_red">{errors.description.message}</Typography>
          )}
        </div>
      </div>

      <div className="align-self-end mb-0">
        <Button
          data-testid="Form-E0DA81"
          className="mr-3"
          priority="secondary"
          size="small"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="Form-F6C9C3"
          size="small"
          type="submit"
          disabled={isLoading || isDisabled}
          loading={isLoading}
        >
          Save
        </Button>
      </div>
    </StyledForm>
  )
}

export default memo(Form)
