import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { createDraftContractForContractor, updateDraftContractForContractor } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'

import { fetchContractors, fetchCountries } from '../../../../services/app.service'
import { RemoCheckFormSection } from '../../remoCheck-form'

export default ({ onNext, onClose, initValue, draft, draftId, isDraftContractLoading, setSaveDraftCallBack }) => {
  const { profile } = useApp()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      first_name: '',
      middle_name: '',
      last_name: '',
      email: '',
      working_country: '',
    },
  })

  const createDraftContract = useMutation({
    mutationFn: (payload) => createDraftContractForContractor(payload),
  })

  const updateDraftContract = useMutation({
    mutationFn: (payload) => updateDraftContractForContractor(draftId, payload),
  })

  const handleSaveDraft = (formValues) => {
    if (isValid) {
      const payload = {
        ...(draft?.contractor_draft_contract_raw_data || initValue),
        profile: {
          first_name: formValues.first_name,
          last_name: formValues.last_name,
          middle_name: formValues.middle_name,
          email: formValues.email,
          home_address: {
            state: formValues.state,
          },
        },
        remo_check: {
          use_remo_check: formValues.use_remo_check === 'true',
          service_plan: formValues.use_remo_check === 'true' ? formValues.service_plan : '',
        },
        working_country: formValues.working_country.value,
        company: profile.id,
      }
      if (draft?.contractor_draft_contract_raw_data || initValue?.contractor_draft_contract_raw_data) {
        updateDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
      } else {
        createDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
      }
      queryClient.refetchQueries(fetchContractors.key)
      onClose()
      navigate('/pages/team')
    }
  }

  // useEffect(() => {
  //   if (isValid) {
  //     setSaveDraftCallBack(() => () => handleSaveDraft(getValues()))
  //   } else {
  //     setSaveDraftCallBack(null)
  //   }
  // }, [isValid])

  const { isLoading: loadingCountry, data: countries } = useQuery('fetchAllowedContractorCountries', {
    queryFn: () =>
      fetchCountries({
        allowed_for_contractors: true,
        include_all_countries: true,
        limit: 1000,
        offset: 0,
        ordering: 'name',
        nium_supported: true,
      }),
  })

  useEffect(() => {
    if (draft) {
      const selectedCountry = countries?.results.find((country) => country.id === draft?.working_country?.id)
      reset({
        first_name: draft.profile?.first_name || '',
        middle_name: draft.profile?.middle_name || '',
        last_name: draft.profile?.last_name || '',
        email: draft.profile?.email || '',
        working_country: {
          value: selectedCountry ? selectedCountry.id : '',
          label: selectedCountry ? selectedCountry.name : '',
        },
        state: draft.profile?.home_address?.state || '',
        use_remo_check: String(draft.remo_check?.use_remo_check) || 'false',
        service_plan: draft.remo_check?.service_plan || '',
      })
    }
  }, [countries?.results, draft, reset])

  const submit = (formValues) => {
    onNext({
      profile: {
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        middle_name: formValues.middle_name,
        email: formValues.email,
        home_address: {
          state: formValues.state,
        },
      },
      remo_check: {
        use_remo_check: formValues.use_remo_check === 'true',
        service_plan: formValues.use_remo_check === 'true' ? formValues.service_plan : '',
      },
      working_country: formValues.working_country.value,
    })
    // setSaveDraftCallBack(null)
  }

  if (isDraftContractLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={32} speed={1} color="black" />
      </div>
    )
  }

  return (
    <form className="d-flex flex-column h-100 employees-page__form" onSubmit={handleSubmit(submit)}>
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Input
            data-testid="BasicInformationForm-E5C087"
            {...register('first_name', { required: 'First name is required' })}
            type="text"
            isRequired
            label="First name"
            placeholder="Enter first name"
          />
          {errors.first_name && (
            <Typography className="text_regular__14 color_red">{errors.first_name.message}</Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="BasicInformationForm-154AEF"
            {...register('middle_name')}
            type="text"
            label="Middle name"
            placeholder="Enter middle name"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="BasicInformationForm-06B789"
            {...register('last_name', { required: 'Last name is required' })}
            isRequired
            type="text"
            label="Last name"
            placeholder="Enter last name"
          />
          {errors.last_name && (
            <Typography className="text_regular__14 color_red">{errors.last_name.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="BasicInformationForm-ACEC2F"
            {...register('email', {
              required: 'Email is required',
              validate: {
                isEmail: (v) => {
                  return /.+@.+\..+/.test(v) || 'Please enter correct email'
                },
              },
            })}
            type="email"
            label="Email"
            isRequired
            placeholder="Personal email"
            tooltip={
              <Tooltip id={v4()} content="An invitation will be sent to this email address for platform access." />
            }
          />

          {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="working_country"
            rules={{ required: 'Country is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="BasicInformationForm-47A72B"
                  {...field}
                  isRequired
                  label="Country of hire"
                  loading={loadingCountry}
                  placeholder="Enter country"
                  options={
                    countries?.results
                      ?.filter(({ allowed_by_default, need_confirmation }) => allowed_by_default || need_confirmation)
                      .map((country) => ({
                        value: country.id,
                        label: country.name,
                      })) || []
                  }
                  tooltip={
                    <Tooltip
                      id={v4()}
                      clickable
                      style={{ width: '500px' }}
                      content="The country where the contractor resides and is recognized as a taxpayer for income tax purposes."
                    />
                  }
                />
              )
            }}
          />
          {errors.working_country && (
            <Typography className="text_regular__14 color_red">{errors.working_country.message}</Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="BasicInformationForm-84A282"
            {...register('state')}
            type="text"
            label="Region / State / Province"
            placeholder="Enter"
          />
        </div>
        <RemoCheckFormSection control={control} isTargetContractor />
        <div className="mt-5 pb-5 d-flex gap-4 align-self-center">
          {/* {(!draft || (draft && draft.state === 'draft')) && (
            <Button
              priority="secondary"
              onClick={handleSubmit(handleSaveDraft)}
              className="align-self-end"
              disabled={!isValid}
            >
              Save draft and exit
            </Button>
          )} */}
          <Button data-testid="BasicInformationForm-3FCD79" type="submit" className="align-self-end">
            Save and continue
          </Button>
        </div>
      </section>
    </form>
  )
}
