import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { createDraftContractForContractor, updateDraftContractForContractor } from '@services/contract.service'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useMutation } from 'react-query'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 500px;
  }
  .modal-header {
    padding: 24px 24px 15.5px 24px;
    margin-bottom: 0;
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    padding-top: 0;
  }
`

export const InviteContractorModal = ({
  inviteContractorOpen,
  onFinish,
  invitedContractor,
  loading,
  draftId,
  draft,
  setDraftContractId,
  invitedContractId,
}) => {
  const { profile } = useApp()

  const createDraftContract = useMutation({
    mutationFn: (payload) => createDraftContractForContractor(payload),
    onSuccess: (res) => {
      setDraftContractId(res.id)
      inviteContractorOpen.setFalse()
    },
  })

  const updateDraftContract = useMutation({
    mutationFn: (payload) => updateDraftContractForContractor(draftId, payload),
    onSuccess: (res) => {
      setDraftContractId(res.id)
      inviteContractorOpen.setFalse()
    },
  })

  const handleInvite = () => {
    if (draft) {
      const payload = { ...invitedContractor, contractor_draft_contract: draftId }
      onFinish({ ...payload, raw_data: payload })
    } else {
      onFinish({ ...invitedContractor, raw_data: invitedContractor })
    }
  }

  const handleInviteLater = () => {
    const payload = { ...invitedContractor, company: profile.id, raw_data: invitedContractor } // BE needs this approach
    if (draft && !invitedContractId) {
      updateDraftContract.mutate(payload)
    } else {
      createDraftContract.mutate(payload)
    }
  }

  const handleCloseModal = () => {
    inviteContractorOpen.setFalse()
    handleInviteLater()
  }

  return (
    <StyledModal show centered onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20">Invite contractor</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="color_grey">
          Would you like to invite {invitedContractor.profile.email} onto the RemoFirst platform for them to start
          self-onboarding (including ID, proof of address and other necessary checks)?
        </Typography>
        <Typography className="color_grey mt-4">You can always invite them later.</Typography>
      </Modal.Body>
      <Modal.Footer>
        <OverlayTrigger placement="top" overlay={<Tooltip>Under development...</Tooltip>}>
          <span>
            <Button type="button" size="small" priority="secondary" onClick={handleInviteLater} disabled>
              Invite later
            </Button>
          </span>
        </OverlayTrigger>
        {/* <Button
          type="button"
          size="small"
          priority="secondary"
          onClick={handleInviteLater}
          disabled={createDraftContract.isLoading || updateDraftContract.isLoading}
        >
          Invite later
        </Button> */}
        <Button type="submit" size="small" priority="primary" onClick={handleInvite} disabled={loading}>
          {loading ? 'Loading...' : 'Invite now'}
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
