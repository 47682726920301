import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { EMPLOYEE_PAY_TYPE, EMPLOYEE_PAYMENT_FREQUENCY_MAP, RECURRENCE_FREQUENCY_TYPES_MAP } from '@core/constants'
import { useApp } from '@core/context'
import { infoIcon } from '@core/icons/icons'
import { setCurrencyChangeEvent } from '@core/utils'
import { AdditionalCompensationModal } from '@pages/employees/create-employee/additional-compensation-modal/additional-compensation-modal.component'
import { AdditionalPaymentsTable } from '@pages/employees/create-employee/additional-payments-table/additional-payments-table.component'
import { AllowanceModal } from '@pages/employees/create-employee/allowance-modal/allowance-modal.component'
import { parseSigningBonus } from '@pages/employees/create-employee/create-employee.utils'
import { checkAnnualSalary } from '@services/contract.service'
import { fetchCountryDetailById, getCountryMinSalariesList } from '@services/countries.service'
import { getCostCalculatorList, getPartnerCostCalc } from '@services/employer-cost.service'
import { createOnboardingAP, deleteOnboardingAP, updateOnboardingAP } from '@services/salary-change.service'
import { patchContractById } from '@store/contracts'
import { defaultTo } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Button as BSButton, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import styled from 'styled-components'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

const ButtonLink = styled(BSButton)`
  color: #40b84c;
  font-size: 14px;
`

export default ({ employee }) => {
  const [disableCurrencySelect, setDisableCurrencySelect] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [signingBonus, setSigningBonus] = useState(null)
  const allowanceModalOpen = useBoolean(false)
  const additionalCompensationModalOpen = useBoolean(false)
  const [countrySalary, setCountrySalary] = useState(null)
  const working_country = employee.working_country.id

  const queryClient = useQueryClient()

  const { currencies, profile, userProfile } = useApp()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
    register,
  } = useForm({
    defaultValues: {
      currency: '',
      yearly_gross_salary: '',
      note: '',
      allowances: [],
      additional_compensations: [],
      signingBonus: 0,
      employee_pay_type: employee?.compensation?.employee_pay_type,
      employee_payment_frequency: employee?.compensation?.employee_payment_frequency,
      employee_work_hours_per_week: employee?.compensation?.employee_work_hours_per_week,
      employee_base_hourly_rate: employee?.compensation?.employee_base_hourly_rate,
    },
  })

  const watchAllowances = watch('allowances')
  const watchAdditionalCompensations = watch('additional_compensations')
  const currencyWatch = watch('currency')
  const salaryWatch = watch('yearly_gross_salary')
  const watchEmployeePayType = watch('employee_pay_type')
  const signingBonusCurrency = watch('signingBonusCurrency')
  const signingBonusAmount = watch('signingBonus')

  const [debounceSalary, setDebounceSalary] = useDebounceValue(salaryWatch, 1000)
  const showAnnualSalaryWarning = useBoolean(false)

  const [employerCostAllowances, setEmployerCostAllowances] = useState([])
  const [employerCostCompensations, setEmployerCostCompensations] = useState([])

  const addPmtListMutation = useMutation([getCostCalculatorList.key], {
    mutationFn: (partner) =>
      getCostCalculatorList.fetch({
        usage_area: 'additional_payment',
        limit: 100,
        offset: 0,
        country_id: working_country,
        partner,
        company: {
          user_id: userProfile.id,
        },
      }),
    onSuccess: (res) => {
      setEmployerCostAllowances(res?.results?.filter((ap) => ap.additional_payment_category === 'allowance'))
      setEmployerCostCompensations(res?.results?.filter((ap) => ap.additional_payment_category === 'compensation'))
    },
  })

  useQuery([getPartnerCostCalc.key, employee.residency_type, working_country], {
    enabled: !!working_country && !!employee.residency_type,
    queryFn: () =>
      getPartnerCostCalc.fetch({
        company: profile.id,
        residency_type: employee.residency_type,
        is_test: profile.is_test,
        working_country,
      }),
    onSuccess: (res) => {
      addPmtListMutation.mutate(res)
    },
  })

  const putEE = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {},
  })

  useEffect(() => {
    setValue('currency', {
      value: employee.compensation.currency.id,
      label: employee.compensation.currency.short_code,
    })
    setValue('signingBonusCurrency', {
      value: employee.compensation.currency.id,
      label: employee.compensation.currency.short_code,
    })
    setValue('yearly_gross_salary', employee.compensation.yearly_gross_salary)
    setValue('signingBonus', employee.compensation.signing_bonus?.amount || 0)
    setSigningBonus(employee.compensation.signing_bonus)
    setValue(
      'allowances',
      employee.compensation.allowances?.map(
        ({
          name,
          amount,
          currency,
          recurrence_frequency,
          is_contractual,
          tax,
          id,
          is_indefinite,
          occurences,
          sub_ap_type,
        }) => ({
          title: name,
          amount,
          tax,
          currency: { label: currency.short_code },
          frequency: {
            label: RECURRENCE_FREQUENCY_TYPES_MAP[recurrence_frequency || 'ONE_TIME'],
            value: recurrence_frequency || 'ONE_TIME',
          },
          sub_ap_type,
          isContractual: is_contractual,
          id,
          hasEnd: !is_indefinite,
          occurences,
        })
      ) || []
    )
    setValue(
      'additional_compensations',
      employee.compensation.additional_compensations?.map(
        ({
          name,
          amount,
          tax,
          currency,
          recurrence_frequency,
          is_contractual,
          id,
          is_indefinite,
          occurences,
          sub_ap_type,
        }) => ({
          title: name,
          amount,
          currency: { label: currency.short_code },
          frequency: {
            label: RECURRENCE_FREQUENCY_TYPES_MAP[recurrence_frequency || 'ONE_TIME'],
            value: recurrence_frequency || 'ONE_TIME',
          },
          tax,
          isContractual: is_contractual,
          id,
          sub_ap_type,
          hasEnd: !is_indefinite,
          occurences,
        })
      ) || []
    )
  }, [employee.compensation])

  const {
    data: countryDetail,
    isLoading: isLoadingCountryDetail,
    isSuccess: isSuccessCountryDetail,
  } = useQuery(['countryDetailQuery', working_country], {
    enabled: !!working_country,
    queryFn: () => fetchCountryDetailById(working_country),
    onSuccess: ({ currency, allow_all_currencies }) => {
      setDisableCurrencySelect(!allow_all_currencies)
    },
  })

  useQuery(['check-annual-salary', debounceSalary, currencyWatch], {
    queryFn: () =>
      checkAnnualSalary({
        currency_short_code: currencies.find(({ id }) => currencyWatch.value === id)?.short_code,
        amount: debounceSalary,
      }),
    onSuccess: () => {
      showAnnualSalaryWarning.setFalse()
    },
    onError: () => {
      showAnnualSalaryWarning.setTrue()
    },
    enabled: !!debounceSalary && !!currencyWatch,
  })

  const createAP = useMutation({
    mutationFn: ({ id, body }) => createOnboardingAP(body, id),
    onSuccess: () => {
      queryClient.invalidateQueries(['detailEmployee'])
      allowanceModalOpen.setFalse()
      additionalCompensationModalOpen.setFalse()
    },
    onError: (error) => {
      // showError(getErrorMessage(error));
    },
  })

  const updateAP = useMutation({
    mutationFn: ({ id, body }) => updateOnboardingAP(id, body),
    onSuccess: () => {
      queryClient.invalidateQueries(['detailEmployee'])
      allowanceModalOpen.setFalse()
      additionalCompensationModalOpen.setFalse()
    },
    onError: (error) => {
      // showError(getErrorMessage(error));
    },
  })

  const deleteAP = useMutation({
    mutationFn: ({ id }) => deleteOnboardingAP(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['detailEmployee'])
    },
    onError: (error) => {
      // showError(getErrorMessage(error));
    },
  })

  useQuery([getCountryMinSalariesList.key], {
    queryFn: () => getCountryMinSalariesList.fetch(working_country),
    onSuccess: (response) => {
      const today = moment(new Date())

      const relevantCountryMinSalaryByRegion = response.filter((m) => m?.region?.id === employee?.region?.id)

      const relevantCountryMinSalaryByDate = relevantCountryMinSalaryByRegion.find(
        ({ effective_end_date, effective_start_date }) =>
          moment(effective_start_date).isSameOrBefore(today) || moment(effective_end_date).isSameOrAfter(today)
      )

      if (relevantCountryMinSalaryByDate && relevantCountryMinSalaryByDate?.region?.id === employee?.region?.id) {
        setCountrySalary(relevantCountryMinSalaryByDate)
      }
    },
  })

  const minSalary = defaultTo(countrySalary?.minimum_annual_salary, 0)

  const submit = (formValues) => {
    const compensation = {
      yearly_gross_salary: Number(formValues.yearly_gross_salary),
    }
    if (!disableCurrencySelect) {
      compensation.currency = formValues.currency.value
    }
    if (+formValues.signingBonus) {
      compensation.signing_bonus = parseSigningBonus(
        formValues.signingBonus,
        undefined,
        formValues.signingBonusCurrency,
        employee.compensation.signing_bonus.sub_ap_type
      )
    }
    putEE.mutate({ id: employee.id, data: { compensation } })
    if (!+formValues.signingBonus && signingBonus) {
      deleteAP.mutate({ id: signingBonus.id })
    } else if (!signingBonus && +formValues.signingBonus > 0) {
      createAP.mutate({
        id: employee.compensation.id,
        body: {
          ...compensation.signing_bonus,
          // , tax: 0
        },
      })
    } else if (signingBonus && +formValues.signingBonus > 0) {
      updateAP.mutate({ id: signingBonus.id, body: compensation.signing_bonus })
    }
  }

  // Allowances handlers
  const handleAllowanceModalClose = () => {
    allowanceModalOpen.setFalse()
    setSelectedIndex(null)
  }

  const handleAllowanceDelete = (id) => {
    deleteAP.mutate({ id })
  }

  const handleAllowanceEdit = (index) => {
    allowanceModalOpen.setTrue()
    setSelectedIndex(index)
  }

  const apDataToBody = (data) => {
    const body = {
      amount: data.amount,
      tax: data.tax || 0,
      currency: data.currency.value,
      name: data.title,
      is_contractual: data.isContractual,
      is_indefinite: !data.occurrences,
      is_recurrent: data.frequency.value !== 'ONE_TIME',
      occurences: data.occurrences,
      ...(data.frequency.value !== 'ONE_TIME'
        ? {
            recurrence_frequency: data.frequency.value,
          }
        : {}),
      ap_type: data.ap_type,
      sub_ap_type: data.sub_ap_type?.label,
      // tax: 0,
    }
    return body
  }

  const handleAllowanceAddSubmit = (data) => {
    const body = apDataToBody(data)
    createAP.mutate({ id: employee.compensation.id, body })
  }

  const handleAllowanceEditSubmit = (data) => {
    const body = apDataToBody(data)
    updateAP.mutate({ id: data.id, body })
  }

  // Additional compensations handlers
  const handleCompensationModalClose = () => {
    additionalCompensationModalOpen.setFalse()
    setSelectedIndex(null)
  }

  const handleCompensationDelete = (id) => {
    deleteAP.mutate({ id })
  }

  const handleCompensationEdit = (index) => {
    additionalCompensationModalOpen.setTrue()
    setSelectedIndex(index)
  }

  const handleCompensationAddSubmit = (data) => {
    handleAllowanceAddSubmit(data)
  }

  const handleCompensationEditSubmit = (data) => {
    handleAllowanceEditSubmit(data)
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
        <section
          className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center"
          style={{ gap: 16 }}
        >
          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Salary
          </Typography>

          <div className="w-100">
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CompensationForm-8B82B4"
                    {...field}
                    loading={isLoadingCountryDetail}
                    label="Currency"
                    addText="This is the currency of the country of hire"
                    isDisabled={disableCurrencySelect}
                    options={currencies.map((currency) => ({
                      value: currency.id,
                      label: currency.name,
                    }))}
                  />
                )
              }}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
            )}
          </div>

          <div className="w-100">
            <Controller
              control={control}
              name="employee_pay_type"
              rules={{ required: 'Choose one option' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Pay type"
                  options={[
                    {
                      id: 'salaried',
                      text: 'Salaried',
                      value: 'salaried',
                    },
                    {
                      id: 'hourly',
                      text: 'Hourly',
                      value: 'hourly',
                    },
                  ]}
                  isRequired
                  disabled
                />
              )}
            />
            {errors.employee_pay_type && (
              <Typography className="text_regular__14 color_red">{errors.employee_pay_type.message}</Typography>
            )}
          </div>

          {watchEmployeePayType === EMPLOYEE_PAY_TYPE.Hourly && (
            <div className="w-100">
              <Controller
                control={control}
                rules={{
                  required: 'Base hourly rate is required',
                  validate: {
                    positive: (v) => (v && v > 0) || 'Should be greater than 0',
                  },
                }}
                name="employee_base_hourly_rate"
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    label="Base hourly rate"
                    placeholder="0.00"
                    step="0.01"
                    onChange={setCurrencyChangeEvent(field.onChange)}
                    disabled
                  />
                )}
              />

              {errors.employee_base_hourly_rate && (
                <Typography className="text_regular__14 color_red">
                  {errors.employee_base_hourly_rate.message}
                </Typography>
              )}
            </div>
          )}

          <div className="w-100">
            <Controller
              control={control}
              name="employee_payment_frequency"
              rules={{ required: 'Payment frequency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CompensationForm-payment-frequency"
                    {...field}
                    label="Payment frequency"
                    options={Object.keys(EMPLOYEE_PAYMENT_FREQUENCY_MAP).map((key) => ({
                      value: key,
                      label: EMPLOYEE_PAYMENT_FREQUENCY_MAP[key],
                    }))}
                    isDisabled
                  />
                )
              }}
            />
            {errors.employee_payment_frequency && (
              <Typography className="text_regular__14 color_red">
                {errors.employee_payment_frequency.message}
              </Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              label="Weekly hours"
              data-testid="CompensationForm-weekly-hours"
              isRequired
              disabled
              {...register('employee_work_hours_per_week', { required: 'Weekly hours is required' })}
            />
            {errors.employee_work_hours_per_week && (
              <Typography className="text_regular__14 color_red">
                {errors.employee_work_hours_per_week.message}
              </Typography>
            )}
          </div>

          <div className="w-100">
            <Controller
              control={control}
              rules={{
                required: 'Year salary is required',
                min: {
                  value: minSalary,
                  message: `The minimum annual salary in ${countrySalary?.region ? `${countrySalary?.region.name}, ` : ''}${countrySalary?.country.name} is ${minSalary}`,
                },
              }}
              name="yearly_gross_salary"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  addText="Before taxes, benefits, and other payroll deductions"
                  label="Annual gross salary"
                  placeholder="0.00"
                  step="0.01"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />

            {errors.yearly_gross_salary && (
              <Typography className="text_regular__14 color_red">{errors.yearly_gross_salary.message}</Typography>
            )}
          </div>

          {showAnnualSalaryWarning.value && (
            <NotificationBlock text="This salary is unusually low - please ensure you have entered the correct annual salary amount in the field above before continuing" />
          )}

          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Signing bonus{' '}
            <OverlayTrigger
              overlay={
                <Tooltip>Signing bonus will be included in the employment agreement and could be taxable</Tooltip>
              }
            >
              <span>
                <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
              </span>
            </OverlayTrigger>
          </Typography>

          <div className="w-100">
            <Controller
              control={control}
              name="signingBonusCurrency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CompensationForm-SigningBonusCurrency"
                    {...field}
                    loading={isLoadingCountryDetail}
                    label="Currency"
                    addText="You can change the currency to facilitate your input, but the invoice currency will stay the same as agreed in your RemoFirst MSA"
                    options={currencies.map((currency) => ({
                      value: currency.id,
                      label: currency.name,
                    }))}
                    isDisabled
                  />
                )
              }}
            />
            {errors.signingBonusCurrency && (
              <Typography className="text_regular__14 color_red">{errors.signingBonusCurrency.message}</Typography>
            )}
          </div>

          <div className="w-100">
            <Controller
              control={control}
              name="signingBonus"
              rules={{
                required: 'Signing bonus is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  addText="One time payment part of the employee’s first payroll; leave 0.00 if it’s not applicable"
                  label="Amount"
                  placeholder="0.00"
                  step="0.01"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {/* eslint-disable-next-line no-unsafe-optional-chaining */}
            {(+signingBonusAmount + +employee?.compensation?.signing_bonus?.tax && (
              <Typography className="text_light__12 color_text_300 mt-1">
                The estimated total amount, including employer taxes and contributions, is{' '}
                {/* eslint-disable-next-line no-unsafe-optional-chaining */}
                {+signingBonusAmount + +employee?.compensation?.signing_bonus?.tax} {signingBonusCurrency?.label}
              </Typography>
            )) ||
              ''}
            {errors.signingBonus && (
              <Typography className="text_regular__14 color_red">{errors.signingBonus.message}</Typography>
            )}
          </div>

          <Button data-testid="CompensationForm-68F902" type="submit" size="small" loading={putEE.isLoading}>
            Save
          </Button>

          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Allowance{' '}
            <OverlayTrigger overlay={<Tooltip>Allowance could be taxable</Tooltip>}>
              <span>
                <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
              </span>
            </OverlayTrigger>
          </Typography>

          <div className="w-100">
            <AdditionalPaymentsTable
              withServer
              data={watchAllowances}
              onEdit={handleAllowanceEdit}
              loadingDelete={deleteAP.isLoading}
              onDelete={handleAllowanceDelete}
            />
            <ButtonLink onClick={allowanceModalOpen.setTrue} variant="link">
              + Add allowance
            </ButtonLink>
          </div>

          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Additional compensation{' '}
            <OverlayTrigger overlay={<Tooltip>Additional compensation could be taxable</Tooltip>}>
              <span>
                <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
              </span>
            </OverlayTrigger>
          </Typography>

          <div className="w-100">
            <AdditionalPaymentsTable
              withServer
              data={watchAdditionalCompensations}
              onEdit={handleCompensationEdit}
              loadingDelete={deleteAP.isLoading}
              onDelete={handleCompensationDelete}
            />
            <ButtonLink onClick={additionalCompensationModalOpen.setTrue} variant="link">
              + Add additional compensation
            </ButtonLink>
          </div>
        </section>
      </form>
      {allowanceModalOpen.value && (
        <AllowanceModal
          mode="update"
          selectedAllowance={getValues('allowances')[selectedIndex]}
          onAdd={handleAllowanceAddSubmit}
          onEdit={handleAllowanceEditSubmit}
          onClose={handleAllowanceModalClose}
          loading={createAP.isLoading || updateAP.isLoading}
          countryId={working_country}
          employerCostAllowances={employerCostAllowances}
          countryCurrency={countryDetail.currency}
        />
      )}
      {additionalCompensationModalOpen.value && (
        <AdditionalCompensationModal
          mode="update"
          selectedCompensation={getValues('additional_compensations')[selectedIndex]}
          onAdd={handleCompensationAddSubmit}
          onEdit={handleCompensationEditSubmit}
          onClose={handleCompensationModalClose}
          loading={createAP.isLoading || updateAP.isLoading}
          countryId={working_country}
          employerCostCompensations={employerCostCompensations}
          countryCurrency={countryDetail.currency}
        />
      )}
    </>
  )
}
