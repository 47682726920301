import './EmployeesPage.scss'

import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import ChooseMeetingTime from '@atoms/ChooseMeetingTimeModal'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import ActionModal from '@components/action-modal/action-modal.component'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { CONTRACT_TYPE, EMPLOYEE_STATES, EOR_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { addContractor, addWhite, searchIcon } from '@core/icons/icons'
import { DraftContractsTable } from '@features/draft-contracts/draft-contracts-table/draft-contracts-table.component'
import { CompleteOnboardingModal } from '@pages/overview/complete-onboarding-modal/complete-onboarding-modal.component'
import { deleteContract, getDirectManagers } from '@services/contract.service'
import { createDraftContract, updateDraftContract } from '@services/draft-contract.service'
import { exportContractorsXLS, exportEmployeesXLS } from '@services/employee.service'
import { resendInvitationLink } from '@services/user.service'
import { fetchCreateContractor } from '@store/contracts'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import { fetchCompanies, fetchContractors } from '../../services/app.service'
import { ContractorsMassImportModal } from './contractors-mass-import-modal/contractors-mass-import-modal.component'
import CreateContractorModal from './create-contractor/CreateContractorModal'
import CreateEmployeeModal from './create-employee/CreateEmployeeModal'
import { allFields, employeeFields, getContractorFields, onboardingFields } from './employeeMock'
import { employeeTableParse } from './parse'
import SuccessModal from './success-modal'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 500px;
  }
  .modal-header {
    padding: 24px 24px 15.5px 24px;
    margin-bottom: 0;
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    padding-top: 0;
  }
`

export default function EmployeesPage() {
  const { countries, profile, refetchProfile } = useApp()
  const { params, setParams } = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const types = location.search.split('type=')
  const [tab, setTab] = useState(0)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [contracts, setContracts] = useState([])
  const [contractorsState, setContractorsState] = useState('')
  const [contractorType, setContractorType] = useState('')
  const [search, setSearch] = useState(params.get('search') || '')
  const [country_id, setCountryId] = useState(params.get('country_id') || '')
  const [lineManagerId, setLineManagerId] = useState(params.get('direct_manager_id') || '')
  const [draftContractId, setDraftContractId] = useState(null)
  const [draftContractorId, setDraftContractorId] = useState(null)
  const [invitedContractId, setInvitedContractId] = useState(null)
  const [debouncedSearch] = useDebounceValue(search, 500)
  const isDeleteContractorModalOpen = useBoolean(false)
  const [contractorIdToDelete, setContractorIdToDelete] = useState(null)

  const newEmployeeModalState = useBoolean(false)
  const newContractorModalState = useBoolean(false)
  const massImportModalState = useBoolean(false)
  const bookMeetModalState = useBoolean(false)
  const msaNotSignedModalState = useBoolean(false)
  const dropdownOpenState = useBoolean(false)
  const completeOnboardingModalState = useBoolean(false)
  const isInvitationModalOpen = useBoolean(false)
  const [invitationPayload, setInvitationPayload] = useState(null)

  const saveAsDraftConfirmModal = useBoolean(false)
  const [saveDraftCallback, setSaveDraftCallback] = useState(null)

  const [stateIn, setStateIn] = useState([
    EMPLOYEE_STATES.ACTIVE,
    EMPLOYEE_STATES.ARCHIVED,
    EMPLOYEE_STATES.COMPLETED,
    EMPLOYEE_STATES.CREATED,
    EMPLOYEE_STATES.DRAFT,
    EMPLOYEE_STATES.ONBOARDING,
    EMPLOYEE_STATES.SIGNING,
    EMPLOYEE_STATES.INVITED,
  ])

  const { successAlert } = useToast()
  const queryClient = useQueryClient()

  const isInactiveDisplayed = stateIn.includes(EMPLOYEE_STATES.INACTIVE)

  const [addedNewEmployee, setAddedNewEmployee] = useState(null)
  const continueContract = useBoolean(false)

  const isNotVerified = profile.state === 'created'

  const { data: lineManagers, isLoading } = useQuery(getDirectManagers.key, getDirectManagers.fetch)

  const deleteContractor = useMutation({
    mutationFn: (id) => deleteContract(id),
    onSuccess: () => {
      isDeleteContractorModalOpen.setFalse()
      toast.success('Contractor deleted succesfully')
      navigate('/pages/team')
    },
  })

  const handleCloseMainModal = () => {
    saveAsDraftConfirmModal.setFalse()
    isInvitationModalOpen.setFalse()
    newContractorModalState.setFalse()
    continueContract.setFalse()

    setInvitedContractId(null)
    setDraftContractorId(null)
    navigate('/pages/team')
    queryClient.refetchQueries(fetchContractors.key)
  }

  const resendInvitation = useMutation({
    mutationFn: (userId) => resendInvitationLink(userId),
    onSuccess: () => {
      toast.success('Invitation link has been sent')
    },
    onError: () => {
      toast.success('Something went wrong')
    },
  })

  const handlePreviewContractor = useCallback(
    (event, row) => {
      event.stopPropagation()
      if (row.contract_type === 'contractor' && row.status === 'onboarding') {
        navigate(`${row.id}/contractor-onboarding`)
      } else if (row.contract_type !== 'contractor' && (row.status === 'onboarding' || row.status === 'created')) {
        navigate(`onboarding/${row.id}`)
      } else navigate(`${row.id}`)
    },
    [navigate]
  )

  const handleDeleteContractor = useCallback(
    (event, row) => {
      event.stopPropagation()
      setContractorIdToDelete(row.id)
      isDeleteContractorModalOpen.setTrue()
    },
    [isDeleteContractorModalOpen]
  )

  const onRowClick = useCallback(
    (row) => {
      if (row.contract_type === 'contractor' && row.status === 'onboarding') {
        navigate(`${row.id}/contractor-onboarding`)
      } else if (row.contract_type !== 'contractor' && (row.status === 'onboarding' || row.status === 'created')) {
        navigate(`onboarding/${row.id}`)
      } else if (row.contract_type === 'contractor' && row.state === 'draft') {
        setDraftContractorId(row.id)
        newContractorModalState.setTrue()
      } else navigate(`${row.id}`)
    },
    [navigate, newContractorModalState]
  )

  const handleOpenDraftContractor = useCallback(
    (event, row) => {
      event.stopPropagation()
      onRowClick(row)
    },
    [onRowClick]
  )

  const handleSendInvitation = useCallback(
    (event, row) => {
      event.stopPropagation()
      if (row.state === 'invited') {
        resendInvitation.mutate(row.user_id)
      } else {
        setInvitationPayload(row)
        isInvitationModalOpen.setTrue()
      }
    },
    [isInvitationModalOpen, resendInvitation]
  )

  const onClickDeleteContractor = () => {
    deleteContractor.mutate(contractorIdToDelete)
  }

  const contractorfields = useMemo(
    () =>
      getContractorFields(
        handleOpenDraftContractor,
        handleSendInvitation,
        handlePreviewContractor,
        handleDeleteContractor
      ),
    [handleDeleteContractor, handleSendInvitation, handleOpenDraftContractor, handlePreviewContractor]
  )

  const { isFetching, refetch } = useQuery(
    [fetchContractors.key, contractorsState, contractorType, page, debouncedSearch, country_id, lineManagerId, stateIn],
    {
      queryFn: () =>
        fetchContractors.fetch({
          company_id: profile.id,
          contract_type: contractorType,
          state: contractorsState,
          offset: (page - 1) * 10,
          limit: 10,
          search: debouncedSearch,
          country_id,
          direct_manager_id: lineManagerId,
          state_in: stateIn.join(','),
        }),
      onSuccess: ({ results, count }) => {
        setContracts(employeeTableParse(results, page))
        setTotal(count)
      },
    }
  )

  const isEmpty = !debouncedSearch && !total

  const companyQuery = useQuery('companies', {
    queryFn: () => fetchCompanies(profile.id),
  })

  const exportEmployeesXLSMutation = useMutation({
    mutationFn: exportEmployeesXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `full_time_employees_${moment(new Date()).format('DD_MM_YYYY')}.xls`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      dropdownOpenState.setFalse()
    },
  })

  const createDraftContractMutation = useMutation({
    mutationFn: createDraftContract,
    onSuccess: () => {
      queryClient.refetchQueries('getDraftContractList')
      successAlert('Your changes have been saved as draft!')
    },
  })

  const updateDraftContractMutation = useMutation({
    mutationFn: ({ id, body }) => updateDraftContract(id, body),
    onSuccess: () => {
      queryClient.refetchQueries('getDraftContractList')
      successAlert('Your changes have been saved!')
    },
  })

  const exportContractorsXLSMutation = useMutation({
    mutationFn: exportContractorsXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `contractors_${moment(new Date()).format('DD_MM_YYYY')}.xls`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      dropdownOpenState.setFalse()
    },
  })

  const handleCloseNewEmployeeModal = (payload) => {
    setAddedNewEmployee(null)
    newEmployeeModalState.setFalse()

    const body = { ...(payload || {}), profile: { ...(payload?.profile || {}) } }
    if (body?.profile?.use_remo_check !== undefined) {
      delete body.profile.use_remo_check
    }
    if (body?.profile?.service_plan !== undefined) {
      delete body.profile.service_plan
    }
    if (body?.job?.probation_period) {
      body.job.probation_period = +body.job.probation_period
    }

    if (payload) {
      if (draftContractId) {
        updateDraftContractMutation.mutate({
          id: draftContractId,
          body: {
            data: body,
          },
        })
      } else {
        createDraftContractMutation.mutate({
          data: body,
          company: profile.id,
        })
      }
    }
    setDraftContractId(null)
    navigate('/pages/team')
    queryClient.refetchQueries(fetchContractors.key)
  }

  const newEmployeeMutation = useMutation({
    mutationFn: (payload) => fetchCreateContractor(payload),
    onSuccess: (v) => {
      refetch()
      refetchProfile()
      handleCloseNewEmployeeModal()
      setAddedNewEmployee(v)
      if (v.contract_type === 'contractor') continueContract.setTrue()
      isInvitationModalOpen.setFalse()
    },
  })

  const inviteNewContractor = useMutation({
    mutationFn: (payload) => fetchCreateContractor(payload),
    onSuccess: () => {
      isInvitationModalOpen.setFalse()
      toast.success('Contractor invited')
      refetch()
    },
    onError: () => {
      newContractorModalState.setTrue()
      isInvitationModalOpen.setFalse()
    },
  })

  const handleAddAnotherPerson = (contract_type) => {
    setAddedNewEmployee(null)
    setInvitedContractId(null)

    if (contract_type === CONTRACT_TYPE.CONTRACTOR) {
      newContractorModalState.setTrue()
    }

    if (contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE) {
      newEmployeeModalState.setTrue()
    }
  }

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    switch (id) {
      case 0: {
        setContractorsState('')
        setStateIn([
          EMPLOYEE_STATES.ACTIVE,
          EMPLOYEE_STATES.ARCHIVED,
          EMPLOYEE_STATES.COMPLETED,
          EMPLOYEE_STATES.CREATED,
          EMPLOYEE_STATES.DRAFT,
          EMPLOYEE_STATES.ONBOARDING,
          EMPLOYEE_STATES.SIGNING,
          EMPLOYEE_STATES.INVITED,
        ])
        return setContractorType('')
      }
      case 1: {
        setContractorsState('')
        return setContractorType(CONTRACT_TYPE.FULL_TIME_EMPLOYEE)
      }
      case 2: {
        setContractorsState('')
        return setContractorType(CONTRACT_TYPE.CONTRACTOR)
      }
      case 3: {
        setContractorType('')
        setContractorsState('')
        setStateIn([EMPLOYEE_STATES.ONBOARDING, EMPLOYEE_STATES.INVITED])
        return setStateIn([EMPLOYEE_STATES.ONBOARDING, EMPLOYEE_STATES.INVITED])
      }
      default:
        return null
    }
  }

  const handleSetOnboardingTab = (contract_type) => {
    if (contract_type === CONTRACT_TYPE.CONTRACTOR) {
      setTab(2)
      onTabChange(2)
    }

    if (contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE) {
      setTab(3)
      onTabChange(3)
    }
    setAddedNewEmployee(null)
    setInvitedContractId(null)
  }

  useEffect(() => {
    if (types.length) {
      onTabChange(+types[1])
      setTab(+types[1])
    }
  }, [location])

  const handleSaveNewEmployee = (payload) => {
    newEmployeeMutation.mutate({
      ...payload,
      company: companyQuery.data.id,
    })
  }

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
    setParams({ search: value, country_id, direct_manager_id: lineManagerId })
    setPage(1)
  }

  const onSelectCountry = (evt) => {
    setCountryId(evt.value || '')
    setParams({
      country_id: evt.value || '',
      search,
      direct_manager_id: lineManagerId,
    })
    setPage(1)
  }

  const onSelectLineManager = (evt) => {
    setLineManagerId(evt ? evt?.value : null)
    setParams({
      direct_manager_id: evt?.value || '',
      search,
      country_id,
    })
    setPage(1)
  }

  // @TODO: create a component for adding employee
  const handleAddEmployeeDialog = () => {
    if (profile?.eor_type === EOR_TYPES.SAAS && !profile?.msa_agreement_document_signed) {
      msaNotSignedModalState.setTrue()
    } else if (profile?.source === 'admin') {
      newEmployeeModalState.setTrue()
    } else if (profile?.source === 'self_onboarded') {
      if (!profile.is_verified) {
        bookMeetModalState.setTrue()
      } else {
        newEmployeeModalState.setTrue()
      }
    }
  }

  const handleOpenDraftContract = (id) => {
    setDraftContractId(id)
    newEmployeeModalState.setTrue()
  }

  const handleGoToMeet = () => {
    navigate('/calendly?page=team')
  }

  const handleGoToDashboard = () => {
    navigate('/pages/dashboard')
  }

  const handleInactiveChange = () => {
    setPage(1)
    setStateIn((prev) =>
      prev.includes(EMPLOYEE_STATES.INACTIVE)
        ? prev.filter((state) => state !== EMPLOYEE_STATES.INACTIVE)
        : [...prev, EMPLOYEE_STATES.INACTIVE]
    )
  }

  const handleAddContractor = () => {
    if (profile?.source === 'self_onboarded' && profile.state !== 'active') {
      completeOnboardingModalState.setTrue()
    } else {
      newContractorModalState.setTrue()
    }
  }

  const handleMassImportModal = () => {
    massImportModalState.setTrue()
    newContractorModalState.setFalse()
  }

  const handleCloseMassImport = () => {
    massImportModalState.setFalse()
    refetch()
  }

  const handleSendInvite = () => {
    const isDraftContractor = invitationPayload?.state === 'draft'
    const contractId = invitationPayload.id
    const payload = {
      ...invitationPayload?.contractor_draft_contract_raw_data,
      ...(isDraftContractor && { contractor_draft_contract: contractId }),
    }

    if (isDraftContractor) {
      inviteNewContractor.mutate({
        ...payload,
        company: companyQuery.data.id,
      })
    }
  }

  const handleCloseNewContractorModal = (callback) => {
    // if (callback) {
    //   setSaveDraftCallback(() => callback)
    //   saveAsDraftConfirmModal.setTrue()
    //   return
    // } // TODO: hided temporarily

    handleCloseMainModal()
  }

  const handleConfirmSaveDraft = () => {
    if (saveDraftCallback) {
      saveDraftCallback()
      saveAsDraftConfirmModal.setFalse()
      handleCloseMainModal()
    }
  }

  if (companyQuery.isLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <div className="employees-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Team </PageTitle>
        <div hidden={isEmpty} className="d-flex">
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Button
                data-testid="EmployeesPage-A367D7"
                priority="secondary"
                size="small"
                onClick={dropdownOpenState.toggle}
              >
                <HiDownload className="mr-1" />
                Download report
              </Button>
            }
            menu={[
              <button
                data-testid="EmployeesPage-6B7E6A"
                type="button"
                onClick={exportEmployeesXLSMutation.mutate}
                disabled={exportEmployeesXLSMutation.isLoading}
              >
                Download FTE data as XLS
              </button>,
              <button
                data-testid="EmployeesPage-209F27"
                type="button"
                onClick={exportContractorsXLSMutation.mutate}
                disabled={exportContractorsXLSMutation.isLoading}
              >
                Download contractors data as XLS
              </button>,
            ]}
          />
          <Button
            data-testid="EmployeesPage-3CDDF0"
            size="small"
            priority="secondary"
            className="ml-2"
            onClick={handleAddContractor}
          >
            <Icon icon={addContractor} className="mr-2" />
            Add contractor
          </Button>
          <Button
            data-testid="EmployeesPage-69A346"
            size="small"
            priority="primary_black"
            className="ml-2"
            onClick={handleAddEmployeeDialog}
          >
            <Icon icon={addWhite} className="mr-2" />
            Add employee
          </Button>
        </div>
      </div>
      {!isEmpty || profile?.has_contracts ? (
        <div className="d-flex mb-4 align-items-center gap-3">
          <Input
            data-testid="EmployeesPage-03313A"
            onChange={onChangeSearch}
            placeholder="Search"
            type="text"
            name="search"
            value={search}
            endIcon={<Icon icon={searchIcon} />}
            styleClass="employees-page-search"
          />
          <div style={{ width: 190 }}>
            <Select
              data-testid="EmployeesPage-A260DA"
              placeholder="Select country"
              value={country_id ? +country_id : undefined}
              onChange={onSelectCountry}
              options={[
                { value: 0, label: 'All' },
                ...countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                })),
              ]}
            />
          </div>
          <div style={{ width: 190 }}>
            <Select
              placeholder="Line manager"
              value={lineManagerId}
              onChange={onSelectLineManager}
              options={lineManagers?.results?.map((manager) => ({
                label: `${manager.profile.first_name} ${manager.profile.last_name}`,
                value: manager.id,
              }))}
              openMenuOnClick={false}
              loding={isLoading}
              isClearable
            />
          </div>
          <Checkbox
            data-testid="EmployeesPage-D55631"
            checked={isInactiveDisplayed}
            label="Inactive"
            onChange={handleInactiveChange}
          />
        </div>
      ) : null}

      {!isEmpty || profile?.has_contracts ? (
        <Tabs selectedTab={tab} onTabChange={onTabChange}>
          <Tab tabId={0} title={isNotVerified ? 'My Team' : 'Everyone'}>
            <CustomTable
              fields={allFields}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              data={contracts}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={1} title="Full-time" hidden={isNotVerified}>
            <CustomTable
              fields={employeeFields}
              data={contracts}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={2} title="Contractors" hidden={isNotVerified}>
            <CustomTable
              fields={contractorfields}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              data={contracts}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={3} title="Onboarding" hidden={isNotVerified}>
            <CustomTable
              fields={onboardingFields}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              data={contracts}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={4} title="Drafts FTE" hidden={isNotVerified}>
            <DraftContractsTable onDraftOpen={handleOpenDraftContract} />
          </Tab>
        </Tabs>
      ) : null}

      {(isEmpty && !profile?.has_contracts && (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <div className="text-center">
            <img src="/assets/img/notFound.png" alt="" />
            <p className="heading_semibold__20 mb-3 mt-4">Start inviting your team members</p>
            <p className="text_light__14 mb-4">Once you add new employees or contractors, you will see them here</p>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                data-testid="EmployeesPage-DE72E1"
                size="small"
                priority="secondary"
                onClick={handleAddContractor}
              >
                <Icon icon={addContractor} className="mr-2" />
                Add contractor
              </Button>
              <Button
                data-testid="EmployeesPage-5ED969"
                size="small"
                priority="primary_black"
                className="ml-2"
                onClick={handleAddEmployeeDialog}
              >
                <Icon icon={addWhite} className="mr-2" />
                Add employee
              </Button>
            </div>
          </div>
        </div>
      )) ||
        ''}

      {newEmployeeModalState.value && (
        <CreateEmployeeModal
          loading={newEmployeeMutation.isLoading}
          onClose={handleCloseNewEmployeeModal}
          onSave={handleSaveNewEmployee}
          draftId={draftContractId}
        />
      )}
      {newContractorModalState.value && (
        <CreateContractorModal
          addedNewEmployee={addedNewEmployee}
          isContinuedContract={continueContract.value}
          onCloseContractModal={continueContract.setFalse}
          onMassImport={handleMassImportModal}
          loading={newEmployeeMutation.isLoading}
          onClose={handleCloseNewContractorModal}
          onSave={handleSaveNewEmployee}
          draftId={draftContractorId}
          invitedContractId={invitedContractId}
        />
      )}
      {!!addedNewEmployee && addedNewEmployee.contract_type === 'full_time_employee' && (
        <SuccessModal
          companyName={companyQuery.data.name}
          employee={addedNewEmployee}
          onAdd={handleAddAnotherPerson}
          onBoarding={handleSetOnboardingTab}
        />
      )}

      {bookMeetModalState.value && <ChooseMeetingTime onClose={bookMeetModalState.setFalse} onMeet={handleGoToMeet} />}

      {msaNotSignedModalState.value && (
        <ActionModal
          imgSrc="/assets/img/sign-document.svg"
          title="Please sign the MSA to start hiring full-time employees globally"
          description="Once you sign the Master Service Agreement, you will be able to add new employees to the platform"
          buttonText="Go to Dashboard to sign"
          onAction={handleGoToDashboard}
          onClose={msaNotSignedModalState.setFalse}
        />
      )}

      {completeOnboardingModalState.value && (
        <CompleteOnboardingModal onClose={completeOnboardingModalState.setFalse} />
      )}

      {massImportModalState.value && <ContractorsMassImportModal onClose={handleCloseMassImport} />}

      <EditModal
        visible={isDeleteContractorModalOpen.value}
        title="Delete contractor"
        footer={false}
        closeModal={isDeleteContractorModalOpen.setFalse}
      >
        <Typography className="heading_semi__16">Are you sure? This contractor will be deleted immediately.</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={isDeleteContractorModalOpen.setFalse}
            disabled={deleteContractor.isLoading}
          >
            Cancel
          </Button>
          <Button
            priority="primary_dangerous"
            size="small"
            onClick={onClickDeleteContractor}
            loading={deleteContractor.isLoading}
            disabled={deleteContractor.isLoading}
          >
            Delete
          </Button>
        </div>
      </EditModal>
      <StyledModal show={isInvitationModalOpen.value} centered onHide={isInvitationModalOpen.setFalse}>
        <Modal.Header closeButton>
          <Typography className="heading_semibold__20">Invite contractor</Typography>
        </Modal.Header>
        <Modal.Body>
          <Typography className="color_grey">
            Would you like to invite {invitationPayload?.profile.first_name} onto the RemoFirst platform for them to
            start self-onboarding (including ID, proof of address and other necessary checks)?
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            size="small"
            priority="secondary"
            onClick={isInvitationModalOpen.setFalse}
            disabled={inviteNewContractor.isLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            size="small"
            priority="primary"
            onClick={handleSendInvite}
            disabled={inviteNewContractor.isLoading}
          >
            {inviteNewContractor.isLoading ? 'Loading...' : 'Yes, send invite now'}
          </Button>
        </Modal.Footer>
      </StyledModal>
      {saveAsDraftConfirmModal.value && (
        <StyledModal show={saveAsDraftConfirmModal.value} centered onHide={saveAsDraftConfirmModal.setFalse}>
          <Modal.Header closeButton>
            <Typography className="heading_semibold__20">Save changes</Typography>
          </Modal.Header>
          <Modal.Body>
            <Typography className="color_grey">Would you like to save the changes as draft?</Typography>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" size="small" priority="secondary" onClick={handleCloseMainModal}>
              No
            </Button>
            <Button type="submit" size="small" priority="primary" onClick={handleConfirmSaveDraft}>
              Yes
            </Button>
          </Modal.Footer>
        </StyledModal>
      )}
    </div>
  )
}
