import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { PAYMENT_FREQUENCY } from '@core/constants'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { fetchContractors } from '@services/app.service'
import {
  createDraftContractForContractor,
  getContractsAvailableCurrency,
  updateDraftContractForContractor,
} from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { v4 } from 'uuid'

const HintText = styled.span`
  font-size: 12px;
  color: #8a96a1;
  margin-top: 4px;
`

export default ({
  initValue,
  loading,
  onNext,
  workingCountryId,
  isEdit,
  setHasMilestones,
  onClose,
  draft,
  draftId,
  isDraftContractLoading,
  isSaveDraftNotAllowed,
  setSaveDraftCallBack,
}) => {
  const {
    control,
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    defaultValues: {
      contractTerm: 'permanent',
      contractor_rate: '',
      currency: null,
      receiving_currency: null,
      estimated_working_hours: null,
      contractor_payment_frequency: '',
    },
    mode: 'onChange',
  })
  const { countries, currencies, profile } = useApp()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data, isFetching } = useQuery({
    queryKey: ['availableCurrencies', workingCountryId],
    queryFn: () => getContractsAvailableCurrency(workingCountryId),
    enabled: !!workingCountryId,
  })
  const [isMilestone, setIsMilestone] = useState(false)
  const country = countries.find((item) => item.id === workingCountryId)
  const contractor_wage_type_watch = watch('contractor_wage_type')
  const frequency = watch('contractor_payment_frequency')
  const isBiWeeklyFrequency = frequency === 'biweekly'
  const isFixed = watch('contractTerm') === 'fixed'
  const start_date = watch('requested_starts_at')
  const end_date = watch('ends_at')
  const cycle_start_at = watch('cycle_start_at')

  const createDraftContract = useMutation({
    mutationFn: (payload) => createDraftContractForContractor(payload),
  })

  const updateDraftContract = useMutation({
    mutationFn: (payload) => updateDraftContractForContractor(draftId, payload),
  })

  const handleSaveDraft = (formValues) => {
    if (isValid) {
      const payload = {
        ...(draft?.contractor_draft_contract_raw_data || initValue),
        compensation: {
          ...(draft?.contractor_draft_contract_raw_data?.compensation || initValue?.compensation),
          currency: +formValues.currency.value,
          receiving_currency: +formValues.receiving_currency.value,
          ...(!isBiWeeklyFrequency && {
            contractor_wage_type: formValues.contractor_wage_type,
            estimated_working_hours: Number(formValues.estimated_working_hours),
          }),
          ...(!isMilestone && {
            contractor_payment_frequency: formValues.contractor_payment_frequency,
            contractor_rate: Number(formValues.contractor_rate),
          }),
          ...(isBiWeeklyFrequency && {
            cycle_start_at: moment(formValues.cycle_start_at).format('YYYY-MM-DD'),
          }),
        },
        job: {
          ...(draft?.contractor_draft_contract_raw_data?.job || initValue?.job),
          requested_starts_at: moment(formValues.requested_starts_at).format('YYYY-MM-DD'),
          starts_at: moment(formValues.requested_starts_at).format('YYYY-MM-DD'),
          ...(isFixed && {
            ends_at: moment(formValues.ends_at).format('YYYY-MM-DD'),
          }),
          is_permanent: !isFixed,
          occupation: initValue.job?.occupation?.id,
        },
        company: profile.id,
      }
      if (draft?.contractor_draft_contract_raw_data || initValue?.contractor_draft_contract_raw_data) {
        updateDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
      } else {
        createDraftContract.mutate({ ...payload, raw_data: payload }) // BE needs this aproach
      }
      queryClient.refetchQueries(fetchContractors.key)
      onClose()
      navigate('/pages/team')
    }
  }

  // useEffect(() => {
  //   if (isValid) {
  //     setSaveDraftCallBack(() => () => handleSaveDraft(getValues()))
  //   } else {
  //     setSaveDraftCallBack(null)
  //   }
  // }, [isValid])

  useEffect(() => {
    const hasMilestone =
      contractor_wage_type_watch === 'milestone' || draft?.compensation?.contractor_wage_type === 'milestone'
    setIsMilestone(hasMilestone)
    if (setHasMilestones) {
      setHasMilestones(hasMilestone)
    }
  }, [contractor_wage_type_watch, draft?.compensation?.contractor_wage_type, setHasMilestones])

  const getContractorReceivingCurrencies = () => {
    if (country?.nium_supported && data?.available_currencies.length) {
      return data.available_currencies.map((c) => ({
        label: `${c.short_code}${c.is_local ? ' (local currency)' : ''}`,
        value: c.id,
      }))
    }
    const defaultCurrencyShortCode = 'USD'
    const localCurrency = country?.currency
    const usdCurrency = currencies.find((item) => item.short_code === defaultCurrencyShortCode)

    return usdCurrency?.id !== localCurrency?.id
      ? [
          {
            label: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
          {
            label: `${localCurrency?.short_code || ''} (local currency)`,
            value: localCurrency?.id,
          },
        ]
      : [
          {
            label: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
        ]
  }

  useEffect(() => {
    if (isEdit && !loading) {
      reset({
        contractTerm: initValue?.job?.is_permanent ? 'permanent' : 'fixed',
        contractor_rate: initValue.compensation?.contractor_rate,
        estimated_working_hours: initValue.compensation?.estimated_working_hours,
        contractor_wage_type: initValue.compensation?.contractor_wage_type,
        contractor_payment_frequency: initValue.compensation?.contractor_payment_frequency,
        currency: {
          value: initValue.compensation?.currency?.id,
          label: `${initValue.compensation?.currency?.name} (${initValue.compensation?.currency?.short_code})`,
        },
        receiving_currency: {
          value: initValue.compensation?.receiving_currency?.id,
          label: initValue.compensation?.receiving_currency?.name,
        },
        cycle_start_at: new Date(initValue.compensation?.cycle_start_at),
        requested_starts_at: new Date(initValue.job?.requested_starts_at),
        starts_at: new Date(initValue.job?.requested_starts_at),
        ends_at: initValue.job?.ends_at ? new Date(initValue.job?.ends_at) : null,
      })
    }
  }, [initValue, isEdit, loading])

  useEffect(() => {
    if (draft) {
      const contractCurrency = currencies?.find((currency) => currency.id === draft?.compensation?.currency?.id)
      const receivingCurrency = currencies?.find(
        (currency) => currency.id === draft?.compensation?.receiving_currency?.id
      )
      reset({
        contractTerm: draft?.job?.is_permanent ? 'permanent' : 'fixed',
        contractor_rate: draft?.compensation?.contractor_rate,
        estimated_working_hours: draft?.compensation?.estimated_working_hours || 0,
        contractor_wage_type: draft?.compensation?.contractor_wage_type,
        contractor_payment_frequency: draft?.compensation?.contractor_payment_frequency,
        currency: {
          value: contractCurrency ? contractCurrency.id : '',
          label: contractCurrency ? `${contractCurrency.name} (${contractCurrency.short_code})` : '',
        },
        receiving_currency: {
          value: receivingCurrency ? receivingCurrency.id : '',
          label: receivingCurrency ? `${receivingCurrency.name} (${receivingCurrency.short_code})` : '',
        },
        cycle_start_at: draft?.compensation?.cycle_start_at ? new Date(draft?.compensation.cycle_start_at) : null,
        requested_starts_at: draft?.job?.requested_starts_at ? new Date(draft?.job?.requested_starts_at) : null,
        starts_at: draft?.job?.requested_starts_at ? new Date(draft?.job?.requested_starts_at) : null,
        ends_at: draft?.job?.ends_at ? new Date(draft?.job?.ends_at) : null,
      })
    }
  }, [currencies, draft, reset])

  const submit = (formValues) => {
    const payload = {
      compensation: {
        currency: +formValues.currency.value,
        receiving_currency: +formValues.receiving_currency.value,
        ...(!isBiWeeklyFrequency && {
          contractor_wage_type: formValues.contractor_wage_type,
          estimated_working_hours: Number(formValues.estimated_working_hours),
        }),
        ...(!isMilestone && {
          contractor_payment_frequency: formValues.contractor_payment_frequency,
          contractor_rate: Number(formValues.contractor_rate),
        }),
        ...(isBiWeeklyFrequency && {
          cycle_start_at: moment(formValues.cycle_start_at).format('YYYY-MM-DD'),
        }),
      },
      job: {
        ...initValue.job,
        requested_starts_at: moment(formValues.requested_starts_at).format('YYYY-MM-DD'),
        starts_at: moment(formValues.requested_starts_at).format('YYYY-MM-DD'),
        ...(isFixed && {
          ends_at: moment(formValues.ends_at).format('YYYY-MM-DD'),
        }),
        is_permanent: !isFixed,
        occupation: initValue.job?.occupation?.id,
      },
    }

    if (contractor_wage_type_watch !== 'hourly') {
      delete payload.compensation.estimated_working_hours
    }

    if (isBiWeeklyFrequency) {
      delete payload.compensation.contractor_wage_type
      delete payload.compensation.estimated_working_hours
    }

    if (isMilestone) {
      delete payload.compensation.contractor_rate
      delete payload.compensation.contractor_payment_frequency
    }

    onNext(payload)
    // setSaveDraftCallBack(null)
  }

  const handleWageTypeChange = (event) => {
    if (setHasMilestones) {
      if (event.target.value === 'milestone') {
        setHasMilestones(true)
      } else {
        setHasMilestones(false)
      }
    }
  }

  if (isFetching) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  if (isDraftContractLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={32} speed={1} color="black" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="contractTerm"
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Contract term"
                options={[
                  {
                    id: 'permanent',
                    text: 'Permanent',
                    value: 'permanent',
                  },
                  {
                    id: 'fixed',
                    text: 'Fixed term',
                    value: 'fixed',
                  },
                ]}
              />
            )}
          />
        </div>
        <div className="d-flex gap-3 w-100 remo-form-input">
          <div className="remo-form-input">
            <Controller
              control={control}
              name="requested_starts_at"
              rules={{ required: 'Start date is required' }}
              render={({ field }) => {
                return (
                  <DateInput
                    label="Start date"
                    disabled={field.disabled}
                    value={field.value}
                    isRequired
                    onChange={(date) => {
                      field.onChange(date)
                    }}
                    maxDate={end_date && new Date(end_date)}
                  />
                )
              }}
            />
            {errors.requested_starts_at && (
              <Typography className="text_regular__14 color_red">{errors.requested_starts_at.message}</Typography>
            )}
          </div>
          {isFixed && (
            <div className="remo-form-input">
              <Controller
                control={control}
                rules={{ required: 'End date is required' }}
                name="ends_at"
                render={({ field }) => {
                  return (
                    <DateInput
                      label="End date"
                      disabled={field.disabled}
                      value={field.value}
                      isRequired={isFixed}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      minDate={start_date && new Date(start_date)}
                    />
                  )
                }}
              />
              {errors.ends_at && (
                <Typography className="text_regular__14 color_red">{errors.ends_at.message}</Typography>
              )}
            </div>
          )}
        </div>

        {!isMilestone && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contractor_payment_frequency"
              rules={{ required: 'Payment frequency is required' }}
              render={({ field }) => {
                return (
                  <RadioButton
                    {...field}
                    isRequired
                    label="Payment frequency"
                    tooltip={
                      <Tooltip
                        id={v4()}
                        clickable
                        style={{ width: '500px' }}
                        content={
                          <div>
                            Payment periods are automatically calculated based on the selection in this column:
                            <ul>
                              <li>Weekly - payments will be automatically created each week;</li>
                              <li>Monthly - payments will be automatically created once a month;</li>
                              <li>Semi-monthly - payments will be automatically created twice a month;</li>
                              <li>
                                Bi-weekly - payments will be automatically created every two weeks starting from the
                                cycle start date;
                              </li>
                              <li>None - payments will not be automatically created;</li>
                            </ul>
                          </div>
                        }
                      />
                    }
                    options={
                      PAYMENT_FREQUENCY.map((item) => ({ id: item.value, text: item.label, value: item.value })) || []
                    }
                  />
                )
              }}
            />
            {errors.contractor_payment_frequency && (
              <Typography className="text_regular__14 color_red">
                {errors.contractor_payment_frequency.message}
              </Typography>
            )}
          </div>
        )}

        {frequency === 'biweekly' && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="cycle_start_at"
              rules={{
                required: 'Cycle start date is required',
                validate: (value) => {
                  return (
                    !start_date ||
                    moment(value).isSameOrAfter(moment(start_date), 'day') ||
                    'Cycle start date can not be earlier than the start date.'
                  )
                },
              }}
              render={({ field }) => {
                return (
                  <DateInput
                    label="Select payment cycle start date"
                    disabled={field.disabled || !start_date}
                    value={field.value}
                    isRequired={frequency === 'biweekly'}
                    onChange={(date) => {
                      field.onChange(date)
                    }}
                    minDate={start_date && new Date(start_date)}
                  />
                )
              }}
            />
            {!start_date && (
              <Typography className="text_regular__14 color_gray">Start date must be selected first</Typography>
            )}
            {cycle_start_at && (
              <Typography className="text_regular__14 color_gray">{`The next payment date after this date will be {${moment(cycle_start_at).add(14, 'days').format('DD MMM YYYY')}}`}</Typography>
            )}
            {errors.cycle_start_at && (
              <Typography className="text_regular__14 color_red">{errors.cycle_start_at.message}</Typography>
            )}
          </div>
        )}

        {frequency !== 'biweekly' && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contractor_wage_type"
              rules={{ required: 'Wage type is required' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  isRequired
                  label="Rate type"
                  options={[
                    {
                      id: 'monthly2',
                      text: 'Monthly',
                      value: 'monthly',
                    },
                    {
                      id: 'daily',
                      text: 'Daily',
                      value: 'daily',
                    },
                    {
                      id: 'hourly',
                      text: 'Hourly',
                      value: 'hourly',
                    },
                    {
                      id: 'milestone',
                      text: 'Milestone',
                      value: 'milestone',
                    },
                  ]}
                  onChange={(event) => {
                    field.onChange(event)
                    if (setHasMilestones) {
                      handleWageTypeChange(event)
                    }
                  }}
                />
              )}
            />
            {errors.contractor_wage_type && (
              <Typography className="text_regular__14 color_red">{errors.contractor_wage_type.message}</Typography>
            )}
          </div>
        )}

        {contractor_wage_type_watch === 'hourly' && (
          <div className="w-100 remo-form-input">
            <Input
              data-testid="CompensationForm-D7B026"
              id={`input_${frequency?.value}`}
              {...register('estimated_working_hours', {
                required: 'Number of hours is required',
              })}
              isRequired
              type="number"
              label={`Number of hours ${
                frequency
                  ? `(estimated ${PAYMENT_FREQUENCY.find((f) => f.value === frequency)?.label.toLowerCase()})`
                  : ''
              }`}
              placeholder="Estimated working hours"
              tooltip={
                <Tooltip
                  id={v4()}
                  clickable
                  style={{ width: '500px' }}
                  content="Enter the estimated number of hours the contractor will work over a chosen period"
                />
              }
            />
            {errors.estimated_working_hours && (
              <Typography className="text_regular__14 color_red">{errors.estimated_working_hours.message}</Typography>
            )}
          </div>
        )}

        {!isMilestone && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contractor_rate"
              rules={{ required: 'Contractor rate is required' }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  required
                  step=".01"
                  label="Contractor rate"
                  placeholder="00.00"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />

            {errors.contractor_rate && (
              <Typography className="text_regular__14 color_red">{errors.contractor_rate.message}</Typography>
            )}
          </div>
        )}

        <div className="remo-form-input">
          <Controller
            control={control}
            name="currency"
            rules={{
              required: 'Contract currency is required.',
              validate: (value) => (value?.value ? true : 'Contract currency is required.'),
            }}
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    isRequired
                    label="Select contract currency"
                    placeholder="Select"
                    options={
                      currencies.map((item) => ({
                        value: item.id,
                        label: `${item.name} (${item.short_code})`,
                      })) || []
                    }
                  />
                  <HintText>
                    {`This is the currency specified in the contract. You will be invoiced in your company's default
                    currency (${profile.default_currency?.short_code}), while the contractor will receive payment in their preferred currency.`}
                  </HintText>
                </>
              )
            }}
          />
          {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}
        </div>

        <div className="remo-form-input">
          <Controller
            control={control}
            name="receiving_currency"
            rules={{
              required: 'Contractor receiving currency is required.',
              validate: (value) => (value?.value ? true : 'Contractor receiving currency is required.'),
            }}
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    isRequired
                    label="Select contractor receiving currency"
                    placeholder="Select"
                    options={getContractorReceivingCurrencies()}
                  />
                  <HintText>
                    {`You will be invoiced in your company's default currency (${profile.default_currency?.short_code}), while the contractor will receive payment in their preferred currency.`}
                  </HintText>
                </>
              )
            }}
          />
          {errors.receiving_currency && (
            <Typography className="text_regular__14 color_red">{errors.receiving_currency.message}</Typography>
          )}
        </div>
        <div className="mt-5 pb-5 d-flex gap-4 align-self-center">
          {/* {!isSaveDraftNotAllowed && (!draft || (draft && draft.state === 'draft')) && (
            <Button
              priority="secondary"
              onClick={handleSubmit(handleSaveDraft)}
              className="align-self-end"
              disabled={!isValid}
            >
              Save draft and exit
            </Button>
          )} */}
          <Button
            data-testid="CompensationForm-1B8E63"
            type="submit"
            className="align-self-end"
            loading={loading}
            disabled={loading}
          >
            Save and continue
          </Button>
        </div>
      </section>
    </form>
  )
}
