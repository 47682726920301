import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import * as icons from '@core/icons/icons'
import { EmployeeStatusTooltip } from '@features/team/employee-status-tooltip/employee-status-tooltip'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'

import { EmployeeSubStatusTooltip } from '../../features/team/employee-sub-status-tooltip/employee-sub-status-tooltip'
import { parseDaysToStart } from './parse'

export const employeeFields = [
  {
    title: '#',
    key: 'number',
    type: 'string',
    maxWidth: 48,
    minWidth: 48,
    width: 48,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    add_key: 'job_position',
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-large">
            <EmployeeStatusTooltip />
          </Tooltip>
        }
      >
        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
          Status <Icon icon={icons.infoIcon} fill="#878787" />
        </span>
      </OverlayTrigger>
    ),
    key: 'status',
    type: 'custom',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
    render: (item) => <Status status={item.state} />,
  },
  {
    title: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-large">
            <EmployeeSubStatusTooltip />
          </Tooltip>
        }
      >
        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
          Sub-status <Icon icon={icons.infoIcon} fill="#878787" />
        </span>
      </OverlayTrigger>
    ),
    key: 'sub_state',
    type: 'custom',
    render: (item) =>
      item.sub_state === 'dummy' ? (
        '-'
      ) : (
        <div className="status status_default">{item.sub_state.toString().replace('_', ' ')}</div>
      ),
    maxWidth: 150,
    minWidth: 150,
  },
]

const renderActionButton = (eventHandler, icon, row) => (
  <Button
    onClick={(event) => eventHandler(event, row)}
    className="text_light__12 px-0 pl-0"
    priority="secondary"
    size="small"
    style={{ height: '32px', minWidth: '32px' }}
  >
    <Icon icon={icon} width={16} height={16} />
  </Button>
)

const renderActionForDraftState = (handleOpenDraftContractor, handleSendInvitation, editIcon, sendIcon, row) => (
  <div className="d-flex gap-2">
    {row.state === 'draft' && (
      <Button
        onClick={(event) => handleOpenDraftContractor(event, row)}
        className="text_light__12 px-0 pl-0"
        priority="secondary"
        size="small"
        style={{ height: '32px', minWidth: '32px' }}
      >
        <Icon icon={editIcon} width={16} height={16} />
      </Button>
    )}
    <OverlayTrigger
      overlay={
        <Popover className="p-2 bg-white">{row.state === 'invited' ? 'Resend invite' : 'Invite contractor'}</Popover>
      }
    >
      <span>
        <Button
          onClick={(event) => handleSendInvitation(event, row)}
          className="text_light__12 px-0 pl-0"
          priority="secondary"
          size="small"
          style={{ height: '32px', minWidth: '32px' }}
        >
          <Icon icon={sendIcon} width={16} height={16} />
        </Button>
      </span>
    </OverlayTrigger>
  </div>
)

const renderActionButtons = (
  row,
  handleOpenDraftContractor,
  handleSendInvitation,
  handlePreviewContractor,
  handleDeleteContractor
) => {
  if (row.state === 'inactive') {
    return renderActionButton(handleDeleteContractor, icons.trashIcon, row)
  }
  if (row.state === 'draft' || row.state === 'invited') {
    return renderActionForDraftState(
      handleOpenDraftContractor,
      handleSendInvitation,
      icons.editIcon,
      icons.sendIcon,
      row
    )
  }
  return renderActionButton(handlePreviewContractor, icons.blackEyeIcon, row)
}

export const getContractorFields = (
  handleOpenDraftContractor,
  handleSendInvitation,
  handlePreviewContractor,
  handleDeleteContractor
) => [
  {
    title: '#',
    key: 'number',
    type: 'string',
    maxWidth: 48,
    minWidth: 48,
    width: 48,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    add_key: 'job_position',
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Receiving currency',
    key: 'receiving_currency',
    type: 'string',
    maxWidth: 200,
    width: 200,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Line manager',
    key: 'direct_manager.full_name',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Action',
    key: 'custom',
    type: 'custom',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          <div className="d-flex gap-2 align-items-center">
            {renderActionButtons(
              row,
              handleOpenDraftContractor,
              handleSendInvitation,
              handlePreviewContractor,
              handleDeleteContractor
            )}
          </div>
        </div>
      )
    },
  },
]

export const allFields = [
  {
    title: '#',
    key: 'number',
    type: 'string',
    maxWidth: 48,
    minWidth: 48,
    width: 48,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    add_key: 'job_position',
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Type',
    key: 'type',
    type: 'string',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Sub-status',
    key: 'sub_state',
    type: 'custom',
    render: (item) =>
      item.sub_state === 'dummy' ? (
        '-'
      ) : (
        <div className="status status_default">{item.sub_state.toString().replace('_', ' ')}</div>
      ),
    maxWidth: 150,
    minWidth: 150,
  },
]

export const onboardingFields = [
  {
    title: 'Name',
    key: 'full_name',
    type: 'string',
    maxWidth: 500,
    add_key: 'job_position',
    minWidth: 500,
    width: 500,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'custom',
    render: (item) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{item.start_at}</span>
        <span style={{ color: '#FFA530' }}>{`In ${parseDaysToStart(item.start_at)} days`}</span>
      </div>
    ),
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Type',
    key: 'type',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Sub-Status',
    key: 'status',
    type: 'custom',
    render: (item) => (item.sub_state === 'dummy' ? '-' : <Status status={item.sub_state} />),
    maxWidth: 150,
    minWidth: 150,
  },
]
